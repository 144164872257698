.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    column-gap: 10px;
    margin: 80px 0px;
}

.footer a div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: var(--border-radius);
    transition: background-color 0.5s;
}

.footer a div:hover {
    background-color: var(--hover-color);
}

.footer a img {
    width: 25px;
    filter: grayscale(10%);
}

@media (max-width: 400px) {
    .footer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 10px;
    }
}

@media (max-width: 300px) {
    .footer {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}