.navigation {
    position: fixed;
    width: 100%;
    z-index: 2;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.white-bg {
    background: var(--background-color);
}

.navigation .navigation-item {
    padding: 8px 15px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: var(--border-radius);
    transition: background-color 0.5s;
    text-decoration: none;
}

.navigation .navigation-item:hover {
    background: var(--hover-color);
}

.hovered-bg {
    background: var(--hover-color);
}


.navigation-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 900px;
    width: 100%;
}



.right-content .theme-switcher{
    cursor: pointer;
}

.right-content .theme-switcher img{
    width: 50px;
    padding: 10px;
    border-radius: var(--border-radius);
}

@media (max-width: 900px) {
    .navigation-content {
        max-width: 100%;
        padding: 0 20px;
    }
}