.work-highlight {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.work-highlight .work-img {
    width: 50px;
}

.work-highlight .work-img img {
    width: 50px;
}

.work-highlight .highlight {
    border: 1px solid var(--border-color);
    padding: 30px 20px;
    display: flex;
    gap: 20px;
    border-radius: var(--border-radius);
}

.work-highlight .highlight .content {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.work-highlight .highlight .content .description {
    color: var(--text-light-color);
}

.work-highlight .highlight .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}


@media (max-width: 450px) {
    .work-highlight .highlight {
        display: flex;
        flex-direction: column;
    }

    .work-highlight .work-img {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}