.details {
    display: flex;
    flex-direction: column;
    row-gap: 100px;
    margin-top: 50px;
}




