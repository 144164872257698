.experience-item {
    border: 1px solid var(--border-color);
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: var(--border-radius);
}

.experience-item .header{
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.experience-item .company-image img {
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.experience-item .company-location {
    font-size: 1rem;
    color: var(--text-light-color);
}

.experience-item .my-details {
    display: flex;
    gap: 10px;
}

.experience-item .my-details .date {
    font-size: 0.8rem;
    color: var(--text-light-color);
}
.experience-item .company-link a{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    background-color: var(--hover-color);
    visibility: hidden;
}

.experience-item:hover .company-link a {
    visibility: visible;
}

.experience-item .tags {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px;
}
.location-wrapper {
    display: flex;
    align-items: center;
    gap: 10px
}
