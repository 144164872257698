.profile-summary {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}
.profile-summary .summary {
    color: var(--text-light-color)
}
.profile-summary .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.profile-summary .profile-image img {
    width: 100%;
}


.profile-summary .profile-details {
    color: var(--text-light-color);
    line-height: 1.6;
    letter-spacing: 0.6px;
}

.profile-summary .profile-details strong {
    background-color: var(--highlight-bg-color);
    border: 1px solid var(--highlight-border-color);
    padding: 0px 2px;
    color: var(--text-strong-color);
    line-height: 150%;
    border-radius: var(--border-radius);
}

.profile-summary .buttons {
    display: flex;
    gap: 20px;
}

@media (max-width: 550px) {
    .profile-summary .buttons {
        flex-wrap: wrap;
    }
}