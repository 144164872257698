.tech-details {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.tech-details .images-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 30px;
    row-gap: 50px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}

.tech-details .tech-img {
    width: 50px;
    height: 50px;
    border-radius: var(--border-radius);
    display: block;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.tech-details .tech-img img {
    height: 100%;
}

.tech-details .text {
    padding: 10px 0;
    text-align: center;
}

@media (max-width: 900px) {
    .tech-details .images-container {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 755px) {
    .tech-details .images-container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 550px) {
    .tech-details .images-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 450px) {
    .tech-details .images-container {
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
    }
}
